import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/college/manager/list',
    update:'/admin/v1/college/manager/update',
    create:'/admin/v1/college/manager/create',
    del:'/admin/v1/college/manager/delete',
    get:'/admin/v1/college/manager/' ,///admin/v1/college/manager/{id} 获取管理员信息
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get(id)
{
    return $h.request(url.get+id,{},'GET')
}