<template>
    <el-drawer
        title="编辑"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-form-item label="用户名" prop="username">
                            <el-input type="text" v-model="form.username" disabled autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="">
                            <el-input type="password" v-model="form.password" placeholder="可填，默认为身份证后6位" autocomplete="off" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="真实姓名" prop="name">
                            <el-input type="text" v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="phone">
                            <el-input type="text" v-model="form.phone" autocomplete="off"></el-input>
                        </el-form-item>
                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">保存</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {update,get} from '@/request/api/user_manager.js'
export default {
    data(){
        return {
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                id:'',
                name:'',
                username:'',
                password:'',
                phone:'',
            },
            
            rules:{
                name:[
                    { required: true, message: '真实姓名必填', trigger: 'blur' },
                ],
                username:[
                    { required: true, message: '用户名必填', trigger: 'blur' },
                ],
                phone:[
                    { required: true, message: '手机号码必填', trigger: 'blur' },
                ],
            },
        }
    },
    created(){
        this.form.id = this.$route.query.id || '';
        this.getUser()
    },
    methods:{
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        //req
        req(){
            let _f = JSON.parse(JSON.stringify(this.form))
            delete _f.username
            update(_f).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
              this.$notify({title: '成功',message: res.msg,type: 'success'});
              this.$router.go(-1)
            })
        },

        getUser(){
            get(this.form.id).then(res=>{
                if(res.code!=0) return;
                Object.keys(this.form).forEach(item=>{
                    if(item != 'password') {
                        this.form[item] = res.data[item]
                    }
                    
                })
            })
        },


        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}

</style>

